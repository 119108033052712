
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { manageAssistList, assistBloggerList, assistBloggerSave, assistBloggerRemove } from "@/api/request/blogger";

//组件
@Component({
  name: "AssistManager",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private manageAssistList: any[] = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    blogger_id_str: "", // 博主ID

    //请求数据
    adm_id: 0, // 代运营ID
    blogger_id: 0, // 博主ID
  };

  //创建
  created() {
    this.getManageAssistList();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await assistBloggerList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.addForm = {
      adm_id: 0, //代运营ID
      blogger_ids: [], //博主ID列表
    };
    this.tags = [];
    this.tagInput = ""; //用户ID文本
    this.tagInputMore = ""; //批量添加ID文本

    //显示界面
    this.dialogVisible = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //获取管理员列表
  private async getManageAssistList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await manageAssistList({});

    //数据赋值
    this.manageAssistList = [];
    this.selectManageAssistList = [];
    this.manageAssistList.push({ id: 0, nick_name: "全部运营管理员" });
    this.selectManageAssistList.push({ id: 0, nick_name: "请选择运营管理员" });
    for (var i: number = 0; i < data.length; i++) {
      this.manageAssistList.push(data[i]);
      this.selectManageAssistList.push(data[i]);
    }
  }

  //处理删除
  private handleDelete(row: any): void {
    this.$confirm(`您确定要删除${row.blogger_name} ？`, "警告", {
      type: "warning",
      cancelButtonText: this.$t("permission.cancel") as string,
      confirmButtonText: this.$t("permission.confirm") as string,
    })
      .then(async () => {
        //删除标签
        await assistBloggerRemove({ id: row.id });

        //显示提示
        this.$message.success("删除成功");

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //------------------------------ 编辑/添加 ------------------------------
  //定义变量
  private tags: any = []; //发放目标
  private tagInput: string = ""; //用户ID文本
  private tagInputMore: string = ""; //批量添加ID文本
  private dialogVisible: boolean = false; //是否显示
  private dialogLoading: boolean = false; //对话框等待
  private selectManageAssistList: any[] = []; //选择管理员列表

  //表单数据
  private addForm: any = {
    adm_id: 0, //代运营ID
    blogger_ids: [], //博主ID列表
  };

  //创建道具
  private async createData() {
    //数据赋值
    var blogger_ids: number[] = [];
    for (var i: number = 0; i < this.tags.length; i++) {
      if (Number(this.tags[i]) != 0) {
        blogger_ids.push(Number(this.tags[i]));
      }
    }

    //判断返回
    if (blogger_ids.length == 0) return Message({ message: "清添加博主ID", type: "error", duration: 5 * 1000 });
    if (this.addForm.adm_id == 0) return Message({ message: "请选择代运营管理员", type: "error", duration: 5 * 1000 });

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.addForm.blogger_ids = blogger_ids;

    //处理编辑
    await assistBloggerSave(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;
    this.dialogLoading = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "添加成功",
      type: "success",
      duration: 2000,
    });
  }

  //添加目标用户
  private addTag(type: any): void {
    //批量添加
    if (type === "more") {
      //定义变量
      let errorList: any = [];

      //数据处理
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());
          this.tags.push(item.trim());
          this.tagInputMore = ""; // 清空输入框
        });

      //显示异常提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //数据处理
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        this.tags.push(this.tagInput.trim());
        this.tagInput = ""; // 清空输入框
      }
      //异常处理
      else {
        this.$message.error("请输入正确形式的ID");
        this.tagInput = "";
      }
    }
  }

  //移除目标用户
  private removeTag(index: any): void {
    this.tags.splice(index, 1);
  }
}
