import { render, staticRenderFns } from "./assist_manager.vue?vue&type=template&id=3856d1fc&scoped=true"
import script from "./assist_manager.vue?vue&type=script&lang=ts"
export * from "./assist_manager.vue?vue&type=script&lang=ts"
import style0 from "./assist_manager.vue?vue&type=style&index=0&id=3856d1fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3856d1fc",
  null
  
)

export default component.exports